import { LoadingPanels } from "app/components/analytics/shared/LoadingStates";
import { Build, Job } from "app/components/build/Show/lib/types";

type Props = {
  build: Build;
  job?: Job;
};

export default function V2TestEngine({ build, job }: Props) {
  const jobId = job ? `jobs/${job?.id}/` : "";
  const id = `te_tests${job ? `_${job.id}` : ""}`;
  const groupBy = Features.TestEngineExecutionGroupingDropdown ? "tests" : "executions";

  return (
    <div data-turbo="true">
      <turbo-frame
        class="w-full h-full"
        id={id}
        src={`/organizations/${build.account.slug}/analytics/builds/${build.id}/${jobId}${groupBy}`}
        data-executions={true}
      >
        <LoadingPanels rows={10} columns={1} />
      </turbo-frame>
    </div>
  );
}
